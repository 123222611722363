.messages-container {
    padding: 20px;
  }
  
  .heading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  .messages-table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .messages-table th {
    background: #f39426;
    color: white;
    text-align: left;
    padding: 12px;
  }
  
  .messages-table td {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .messages-table tr:hover {
    background: #f5f5f5;
  }
  
  .btn-action {
    background: #f39426;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .btn-action:hover {
    background: #ff4500;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 10px;
    margin-top: 10px;
  }
  
  .pagination li {
    padding: 8px 12px;
    margin: 3px;
    cursor: pointer;
    border-radius: 5px;
    background: #ddd;
  }
  
  .pagination .active {
    background: #ff6600;
    color: white;
  }