.myNavbar{
    padding: 10px 20px;
    align-items: baseline;
}

/************************************************************************/

.nav-link-item {
    color: #f39426;
    padding: 10px 15px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .nav-link-item:hover {
    color: #dfab6f; 
  }
  .nav .nav-link-item {
    color: #ffffff; 
  }
  
  .nav .nav-link-item:hover {
    color: #dfab6f; 
  }
  .navbar-brand img {
    max-height: 50px;
  }
  .myNavbar {
    border-bottom: 1px solid #ddd; 
  }
  .myNav {
    margin-top: 10px;
  }
  .myNavDropdown{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #53B4E3;
  }