.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background: white;
  }
  
  .chat-box {
    height: 600px;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
  }  

  
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background: white;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .chat-input button {
    background: #ff6600;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .chat-input button:hover {
    background: #ff4500;
  }
  
  .no-messages {
    text-align: center;
    font-size: 14px;
    color: #777;
  }
  
  .chat-message {
    max-width: 75%;
    padding: 12px;
    border-radius: 10px;
    margin: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .message-header {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    color: #f9f9f9;
  }
  
  .user-msg {
    align-self: flex-start;
    background: #ff6600;
    color: white;
    border-radius: 10px 10px 10px 0px;
    padding: 12px;
  }
  
  .admin-msg {
    align-self: flex-end;
    background: #285598;
    color: white;
    border-radius: 10px 10px 0px 10px;
    padding: 12px;
  }
  
  .message-time {
    font-size: 12px;
    text-align: right;
    opacity: 0.7;
    margin-top: 5px;
  }
  
  .message-link {
    color: #00aaff;
    text-decoration: underline;
    word-break: break-word;
  }
  